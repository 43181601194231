import CryptoJS from 'crypto-js'


//解密方法
function Decrypt(word,key,iv) {
    key = CryptoJS.enc.Utf8.parse(key)
    iv = CryptoJS.enc.Utf8.parse(iv)
    const decrypted = CryptoJS.AES.decrypt(word, key, { 
            iv:iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    return CryptoJS.enc.Utf8.stringify(decrypted);
}

//加密方法
function Encrypt(word,key,iv) {
    key = CryptoJS.enc.Utf8.parse(key)
    iv = CryptoJS.enc.Utf8.parse(iv)
    var encrypted =CryptoJS.AES.encrypt(word,key,  
        {  
            iv:iv,  
            mode:CryptoJS.mode.CBC,  
            padding:CryptoJS.pad.Pkcs7  
        });  
    return encrypted.toString(); 
}

export default {
    Decrypt ,
    Encrypt
}