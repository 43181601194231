<template>
  <div class="loginBox">
    <div class="loginHeader">
      <img :src="loginHeader" alt="">
    </div>

    <div class="loginBgBox">
      <div class="loginContent">

        <div class="signInBox">
          <ul class="signTab">
            <li :class="activeS==1?'signActive':''" @click="tabClick(1)">个人登录</li>
            <li :class="activeS==2?'signActive':''" @click="tabClick(2)">管理登录</li>
          </ul>
          <!-- 个人 -->
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">
            <div v-if="activeS==1" class="personalBox">
              <el-form-item label="" prop="phoneNumber">
                <el-input class="m-top20" maxlength="11" @input="personChange" v-model.trim="ruleForm.phoneNumber" prefix-icon="el-icon-user" placeholder="手机号"></el-input>
              </el-form-item>
              <el-form-item label="" prop="verificationCode">
                <div class="verificationBox m-top20">
                  <el-input autocomplete="off" @input="personChange" @keyup.enter.native="signInKeyup" v-model.trim="ruleForm.verificationCode" placeholder="短信验证码"></el-input>
                  <el-button type="danger" :disabled="show" class="verifBtn" @click="openAdopFun">{{show?time+'秒':'获取验证码'}}</el-button>
                </div>
              </el-form-item>
              <verification @adoptFun="adoptFun" :activeS="activeS" v-if="adoptFlag"></verification>
              <el-button type="primary" :class="personFlag?'loginBtn loginBgColor':'loginBtn'" :loading="loading" @click.native.prevent="handleLogin">登录</el-button>
            </div>
            <!-- 管理 -->
            <div v-if="activeS==2" class="personalBox">
              <el-form-item label="" prop="accountNumber">
                <el-input class="m-top20" v-model.trim="ruleForm.accountNumber" @input="adminChange" placeholder="账号" prefix-icon="el-icon-user"></el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input type="password" class="m-top20" @input="adminChange" @keyup.enter.native="signInKeyup" v-model.trim="ruleForm.password" placeholder="密码" prefix-icon="el-icon-lock"></el-input>
              </el-form-item>
              <verification @adoptFun="adoptFun" :activeS="activeS"></verification>
              <el-button type="primary" :class="adminFlag?'loginBtn loginBgColor':'loginBtn'" :loading="loading" @click.native.prevent="handleLogin">登录</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>

    <div class="footerBox">
      Copyright © 2018-2028 www.wizhrs.com Powered By 北京万智国际人力资源服务有限公司 <span style="margin-left:5px">ICP备案号：</span><a style="color: #999999;" target="_blank" href="https://beian.miit.gov.cn/">京ICP备18042429 号</a>
    </div>
  </div>
</template>
<script>
import loginHeader from '@/assets/loginHeader.png'
import loginText from '@/assets/loginText.png'
import {initAccountOpen,token,sendMessageCode} from '@/api/login/login';
import encryption from './encryption'
import {setToken,removeToken} from '@/utils/auth'
import { mapMutations} from 'vuex'
import verification from './verification'
export default {
  components:{verification},
  data() {
    return {
      activeS:1,
      ruleForm:{
        phoneNumber:'',//手机号
        verificationCode:'',//验证码
        accountNumber:'',//账号
        password:''//密码
      },
      
      rules:{
        phoneNumber:[
          { required:true, message: '手机号不能为空'},
          // { type: 'number', message: '手机号必须为数字值'}
        ],
        verificationCode:[{
          required:true,
          message: '验证码不能为空',
        }],
        accountNumber:[{
          required:false,
          message: '账号不能为空',
        }],
        password:[{
          required:false,
          message: '密码不能为空',
        }]
      },
      
      loginHeader,
      loginText,
      encrypt:{},
      loading:false,
      timer:null,
      show:false,
      time:60,
      timeS:null,
      personFlag:false,
      adminFlag:false,
      adoptFlag: false,
      administrationFlag:true
    }
  },
  mounted() {
    removeToken()
    window.sessionStorage.removeItem('ownToken')
    // window.sessionStorage.removeItem('time')
    this.SET_FLAG(true)



    this.getInit();
    this.timeS =  window.sessionStorage.getItem('time');
    if(this.timeS){
      this.getVerificationCode()
    }
  },
  methods:{
    ...mapMutations(['SET_FLAG']),
    // 初始化
    getInit(){
      initAccountOpen().then(res=>{
        if(res.status == 200){
          let {encryptIv,encryptKey} = res.data;
          this.encrypt = {encryptIv,encryptKey};
        }
      })
    },
    signInKeyup(event){
      this.handleLogin()
    },
    // 个人端
    personChange(){
      if((this.ruleForm.phoneNumber!='')&&(this.ruleForm.verificationCode!='')){
        this.personFlag = true;
      }else{
        this.personFlag = false;
      }
    },
    // 管理
    adminChange(){
      if((this.ruleForm.accountNumber!='')&&(this.ruleForm.password!='')){
        this.adminFlag = true;
      }else{
        this.adminFlag = false;
      }
    },
    // 登录
    handleLogin(){
      this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            const { phoneNumber, verificationCode, accountNumber,password} = this.ruleForm
            const { encryptKey, encryptIv } = this.encrypt
            // let newPassword = encryption.Encrypt(password, encryptKey, encryptIv)
            let params = {};
            if(this.activeS==1){
              params = {
                username:phoneNumber,
                captcha:verificationCode
              }
            }else{
              if(this.administrationFlag){
                this.$message.error('请滑动验证')
                return
              }
              params = {
                username:accountNumber,
                password:password
              }
            }
            this.loading = true;
            token(params).then(res=>{
              if(res.status == 200){
                let arr = Object.keys(res.data);
                if(arr[0]=='ownToken'){
                  window.sessionStorage.setItem('ownToken','ownToken')
                  setToken(res.data.ownToken)
                  clearInterval(this.timer)
                  this.timer = null;
                  window.sessionStorage.removeItem('time')
                  this.$router.replace('/notice').catch(() =>{})
                }else{
                  setToken(res.data.token)
                  this.$router.replace('/noticeInformation').catch(() =>{})
                }
              }else{
                this.$message.error(res.message)
              }
              this.loading = false;
            }).catch(error=>{
              this.loading = false;
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    openAdopFun(){
      if(this.timeS){
        this.show = true;
        this.time = this.timeS
      }else{
        this.time = 60;
        if(!this.ruleForm.phoneNumber){
          this.$message.error('请输入手机号')
          return
        }

        let reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
        if(!reg.test(this.ruleForm.phoneNumber)||this.ruleForm.phoneNumber.length!=11){
          this.$message.error('输入的手机号不正确')
          return
        }
      }
      this.adoptFlag = true
    },
    adoptFun(type){
      if(type == 1){
        this.getVerificationCode()
      } else {
        this.administrationFlag = false;
      }
    },
    // 点击获取验证码
    getVerificationCode(){
      this.timer = setInterval(() => {
        if(this.time>0){
          this.time--;
          window.sessionStorage.setItem('time',this.time)
            this.show = true;
          if(this.time==59){
            this.getCaptcha()
          }
        }else{
          clearInterval(this.timer)
          this.timer = null;
          window.sessionStorage.removeItem('time')
          this.show = false;
          this.timeS = null;
        }
      }, 1000);
    },
    // 获取验证码
    getCaptcha(){
      sendMessageCode({username:this.ruleForm.phoneNumber})
    },
    // tabs
    tabClick(val){
      this.activeS = val;
      if(val==1){
        this.rules.phoneNumber[0].required = true;
        this.rules.verificationCode[0].required = true;
        this.rules.accountNumber[0].required = false;
        this.rules.password[0].required = false;
      }else{
        this.rules.phoneNumber[0].required = false;
        this.rules.verificationCode[0].required = false;
        this.rules.accountNumber[0].required = true;
        this.rules.password[0].required = true;
      }
    }
  }
}
</script>
<style lang="scss">
  .loginBox{
    width: 100%;
    height: 100vh;
    position: relative;

    .loginHeader{
      height: 65px;
      background: rgb(191,22,42);
      display: flex;
      align-items: center;
      img{
        width: 233px;
        margin-left: 50px;
      }
    }
    .loginBgBox{
      width: 100%;
      height: calc(100% - 95px);
      background: #eef2f5;
    }
    
    .loginContent{
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      
      .loginTitle{
        color: #fff;
        text-align: center;
        font-size: 26px;
      }

      .signInBox{
        background: #fff;
        width: 300px;
        height: 300px;
        padding:30px 40px;
        border-radius: 3px;

        .el-form-item{
          margin: 0;
        }

        .signTab{
          display: flex;
          border-bottom:1px solid #DFE0E5;
          li{
            width: 50%;
            text-align: center;
            margin-top: 15px;
            cursor: pointer;
          }
          .signActive{
            color: rgb(240, 15, 21);
            padding-bottom: 10px;
            border-bottom: 3px solid #F00F15;
          }
        }

        .personalBox{
          .verificationBox{
            display: flex;
            .verifBtn{
              width: 110px;
              margin-left: 10px;
              background: rgb(191, 22, 42);
              border: none;
            }
          }
          .loginBtn{
            width: 78%;
            // margin-top: 60px;
            background: rgb(217, 115, 127);
            border: none;
            position:absolute;
            bottom: 30px;
          }

          .loginBgColor{
            background: rgb(191, 22, 42);
          }
        }

      }
    }

    .footerBox{
      background: #555555;
      color: #999999;
      text-align: center;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
    }
  }
</style>