import axios from '@/utils/axios'

// 初始化
export function initAccountOpen(params){
  return axios({
    url:'/api/auth/jwt/initAccountOpen',
    method:'get',
    params
  })
}

// 登录
export function token(data){
  return axios({
    url:'/api/auth/jwt/token',
    method:'post',
    data
  })
}

// 获取验证码
export function sendMessageCode(params){
  return axios({
    url:'/api/auth/jwt/sendMessageCode',
    method:'get',
    params
  })
}
